<template>
  <div class="main-container">
    <div class="main-heading">
      <h1>{{ "Random Rumble" }}</h1>
      <Button
        :onClick="openConfirm"
        type="info"
        text="Submit"
        :loading="isLoading"
      />
    </div>
    <div class="first-section">
      <div class="col">
        <div class="form-field">
          <h4>Name</h4>
          <input
            class="input"
            v-model="event.localizables.name[selectedLanguage]"
          />
        </div>
        <div class="form-field">
          <h4>Short Description</h4>
          <input
            class="input"
            v-model="event.localizables.short_description[selectedLanguage]"
          />
        </div>
        <div class="form-field">
          <h4>Ticket Gem Price</h4>
          <input class="input" type="number" v-model="event.ticket_gem_value" />
        </div>
        <div class="form-field">
          <h4>Card Level</h4>
          <input class="input" type="number" v-model="event.card_level" />
        </div>
        <div class="form-field">
          <h4>Unlocked Arena</h4>
          <input class="input" type="number" v-model="event.unlocked_arena" />
        </div>
        <div class="form-field">
          <h4>Event Win Length</h4>
          <input class="input" type="number" v-model="event.event_length" />
          <a @click="() => applyWinLength()">Apply</a>
        </div>
        <div class="form-field">
            <h4> Critical Damage </h4>
            <input class="input" type="number" v-model="event.critical_damage" />
          </div>
          <div class="form-field">
            <h4> Critical Rate </h4>
            <input  class="input" type="number" v-model="event.critical_rate" />
          </div>
        <div class="form-field">
          <h4>Event Page Asset</h4>
          <multiselect
            v-model="event.event_page_asset"
            :options="assetOptions"
            :multiple="false"
          />
        </div>
        <div class="form-field">
          <h4>Event Inside Asset</h4>
          <multiselect
            v-model="event.inside_asset"
            :options="assetOptions"
            :multiple="false"
          />
        </div>
      </div>
      <div class="col">
        <div class="form-field">
          <h4>Language</h4>
          <select class="input" v-model="selectedLanguage">
            <option
              v-for="language in languages"
              v-bind:key="language.code"
              :value="language.code"
            >
              {{ language.name }}
            </option>
          </select>
        </div>
        <div class="header-with-button">
          <h4>Info Fields</h4>
          <div class="flex">
            <Button
              :loading="false"
              text="Get Defaults"
              size="sm"
              :onClick="() => getDefaultInfoFields()"
              type="info"
            />
            <Button
              :loading="false"
              text="Add"
              size="sm"
              :onClick="() => addInfoField()"
              type="success"
            />
            <Button
              :loading="false"
              text="Remove"
              size="sm"
              :onClick="() => removeInfoField(event.info_fields.length - 1)"
              type="error"
            />
          </div>
        </div>
        <div
          v-for="(field, index) in event.info_fields"
          v-bind:key="index"
          class="info-field-container"
        >
          <div class="form-container">
            <p>Icon</p>
            <select class="input" v-model.number="field.icon">
              <option
                v-for="type in eventConstants.INFO_FIELD_ICONS"
                v-bind:key="type.value"
                :value="type.value"
              >
                {{ $t(type.text) }}
              </option>
            </select>
          </div>
          <div class="form-container">
            <p>{{ "Field Title" }}</p>
            <input class="input" v-model="field.name[selectedLanguage]" />
          </div>
          <div class="form-container">
            <p>{{ "Text" }}</p>
            <textarea class="input" v-model="field.text[selectedLanguage]" />
          </div>
        </div>
      </div>
    </div>
    <h4>Event Rewards</h4>
    <div class="table">
      <div class="row row-header">
        <div class="cell cell-header" :style="{ width: 100 / 5 + '%' }">
          Reward
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 5 + '%' }">
          Type
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 5 + '%' }">
          Rarity
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 5 + '%' }">
          Chest
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 5 + '%' }">
          Amount
        </div>
      </div>
      <div
        v-for="(reward, index) in event.rewards"
        v-bind:key="index"
        class="row"
      >
        <div class="cell" :style="{ width: 100 / 5 + '%' }">
          {{ index + 1 + " Wins" }}
        </div>
        <div class="cell" :style="{ width: 100 / 5 + '%' }">
          <select
            v-model.number="event.rewards[index].type"
            @change="initializeRewardType(index, event.rewards[index].type)"
          >
            <option
              v-for="option in constants.REWARD_TYPES"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ $t("REWARD_VALUES." + option.text) }}
            </option>
          </select>
        </div>
        <div class="cell" :style="{ width: 100 / 5 + '%' }">
          <select
            v-model.number="event.rewards[index].rarity"
            :disabled="![3, 4].includes(event.rewards[index].type)"
          >
            <option
              v-for="option in constants.CARD_RARITY"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ $t("REWARD_VALUES." + option.text) }}
            </option>
          </select>
        </div>
        <div class="cell" :style="{ width: 100 / 5 + '%' }">
          <select
            v-model="event.rewards[index].id"
            :disabled="event.rewards[index].type !== 2"
          >
            <option
              v-for="option in chests"
              :value="option.id"
              v-bind:key="option.id"
            >
              {{ option.name }}
            </option>
          </select>
        </div>
        <div class="cell" :style="{ width: 100 / 5 + '%' }">
          <input type="number" v-model.number="event.rewards[index].amount" />
        </div>
      </div>
    </div>
    <ConfirmPopup
      :popup-open="popupOpen"
      :text="popupText"
      @popup-close="popupOpen = false"
      :type="popupType"
      :changes="changes"
      @confirm="submit"
    />
  </div>
</template>

<script>
import eventConstants from "@/constants/eventConstants";
import {
  watch,
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  onBeforeMount,
} from "vue";
import languageConstants from "@/constants/languagesTemp";
import eventSchema from "@/schemas/event";
import { useStore } from "vuex";
import Button from "../../components/common/Button.vue";
import dispatchMap from "@/constants/dispatchMap";
import { io } from "socket.io-client";
import ConfirmPopup from "../../components/common/ConfirmPopup.vue";
import changeDetector from "@/util/changeDetector";

export default {
  name: "EventRandomRumble",
  components: {
    Button,
    ConfirmPopup,
  },
  setup() {
    const constants = {
      REWARD_TYPES: [
        {
          text: "GOLD",
          value: 0,
        },
        {
          text: "GEM",
          value: 1,
        },
        {
          text: "CHEST",
          value: 2,
        },
        {
          text: "UNIT",
          value: 3,
        },
        {
          text: "HERO",
          value: 4,
        },
      ],
      CARD_RARITY: [
        {
          text: "COMMON",
          value: 0,
        },
        {
          text: "RARE",
          value: 1,
        },
        {
          text: "EPIC",
          value: 2,
        },
        {
          text: "LEGENDARY",
          value: 3,
        },
      ],
    };

    const store = useStore();
    const selectedLanguage = ref("en");
    const languages = languageConstants.languages;
    const usedPages = ["events"];
    const socket = io(process.env.VUE_APP_BACKEND_URL);
    const popupOpen = ref(false);
    const popupTexts = [
      "This page is edited by another user. Please refresh before making changes.",
      "Your changes: ",
    ];
    const popupText = ref(popupTexts[0]);
    const popupType = ref("refresh");
    const event = ref({});
    const isLoading = ref(false);
    const changes = ref([]);
    const events = computed(() => store.getters["events/getEvents"]);
    const chests = computed(() => store.getters["chests/getChests"]);
    const assetList = computed(() => {
      return [
        {
          doc_ref: undefined,
        },
        ...store.getters["assets/getAssets"].files,
      ];
    });
    const assetOptions = computed(() => {
      let assetArray = []
      assetList.value.forEach(asset => {
        if(asset.doc_ref === undefined){
          assetArray.push("DEFAULT BANNER")
        }
        else{
          assetArray.push(asset.doc_ref)
        }
      })
      return assetArray
    });

    onBeforeMount(() =>
      usedPages.forEach((page) => {
        dispatchMap[page].forEach((dispatchStr) => store.dispatch(dispatchStr));
      })
    );

    onMounted(() => {
      if (events.value.length > 0) {
        event.value = JSON.parse(
          JSON.stringify(events.value.find((x) => x.type === 2) || {})
        );
      }
      if (!event.value.info_fields) {
        event.value.info_fields = [{ icon: 0, name: "", text: "" }];
        event.value.localizables = { name: {}, short_description: {} };
      }
      window.firstRandomRumble = JSON.parse(JSON.stringify(event.value));
      store.dispatch("loader/loadingStatus", false);
    });
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    watch(
      () => store.getters["events/getEvents"],
      (value) => {
        event.value = JSON.parse(
          JSON.stringify([...value].find((x) => x.type === 2) || {})
        );
        if (!event.value.info_fields) {
          event.value.info_fields = [{ icon: 0, name: "", text: "" }];
          event.value.localizables = { name: {}, short_description: {} };
        }
        window.firstRandomRumble = JSON.parse(JSON.stringify(event.value));
      },
      { immediate: true }
    );

    const applyWinLength = () => {
      if (event.value.event_length > event.value.rewards.length) {
        const newRewardsArray = JSON.parse(JSON.stringify(event.value.rewards));
        for (
          let i = 0;
          i < event.value.event_length - event.value.rewards.length;
          i++
        ) {
          newRewardsArray.push({ type: 0, amount: 0 });
        }
        event.value.rewards = newRewardsArray;
      } else {
        event.value.rewards = event.value.rewards.slice(
          0,
          event.value.event_length
        );
      }
    };

    const initializeRewardType = (index, type) => {
      const reward = {
        type,
        amount: 0,
      };
      if ([3, 4].includes(type)) reward.rarity = 0;
      if (type === 2) reward.id = "";
      event.value.rewards[index] = reward;
    };

    const submit = () => {
      isLoading.value = true;
      store
        .dispatch("events/updateEvent", {
          id: "random_rumble",
          updateBody: event.value,
        })
        .then(() => {
          isLoading.value = false;
          socket.emit("update", {
            socketId: socket.id,
            itemId: "random_rumble",
            page: "Random Rumble",
            data: event.value,
          });
          popupOpen.value = false;
          changes.value = [];
        })
        .catch(() => {
          isLoading.value = false;
        });
    };

    socket.on("updated", (data) => {
      if (
        data.socketId != socket.id &&
        data.itemId === "random_rumble" &&
        data.page === "Random Rumble" &&
        data.data
      ) {
        popupOpen.value = true;
        popupType.value = "refresh";
        popupText.value = popupTexts[0];
        changes.value = changeDetector.detect(
          event.value,
          data.data,
          "eventRandomRumble"
        );
      }
    });

    const openConfirm = () => {
      popupType.value = "confirm";
      popupOpen.value = true;
      popupText.value = popupTexts[1];
      changes.value = changeDetector.detect(
        window.firstRandomRumble,
        event.value,
        "eventRandomRumble"
      );
    };

    const addInfoField = () => {
      event.value.info_fields.push({ icon: 0, name: {}, text: {} });
    };

    const removeInfoField = (index) => {
      event.value.info_fields.splice(index, 1);
    };

    const getDefaultInfoFields = () => {
      event.value.info_fields = eventSchema.getRandomRumbleDefaultRules();
    };

    return {
      event,
      applyWinLength,
      chests,
      constants,
      assetList,
      assetOptions,
      initializeRewardType,
      isLoading,
      submit,
      popupOpen,
      popupText,
      popupType,
      selectedLanguage,
      languages,
      eventConstants,
      addInfoField,
      removeInfoField,
      getDefaultInfoFields,
      changes,
      openConfirm,
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.main-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input {
  width: 300px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-right: 10px;
}

.input-multi {
  width: 300px;
  height: 35px;
}

.first-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: start;
}

a {
  color: blue;
  text-decoration: underline;
}

a:hover {
  cursor: pointer;
}

.row {
  flex-grow: 1;
  max-width: 100%;
  display: inline-flex;
  max-height: 70px;
  overflow: hidden;
}

.cell.cell-header {
  font-size: 0.95rem;
  font-weight: 600;
  border-bottom: 1px solid gray;
}

.cell {
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table {
  width: 100%;
  flex-grow: 1;
  max-width: 85vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 15px 0px;
}

.table select {
  width: 90%;
  height: 35px;
}

.table input {
  width: 90%;
  height: 35px;
}

.info-field-container {
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  margin-bottom: 20px;
}

.header-with-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.flex {
  display: flex;
}

.margin {
  margin: 10px;
}
</style>